@import "./utils";

.page-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  color: white;

  &.game {
    justify-content: normal;
  }
}


.button {
  padding: .75rem;
  box-sizing: border-box;

  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  font-size: 1rem;
  color: white;
  background-color: $button-background-color;
  transition: background-color .17s ease, color .17s ease;

  &:hover, &:focus {
    background-color: transparentize($button-background-color, 0.1); //#677bc4
  }


  &[disabled] {
    background-color: gray;
    cursor: initial;

    &:hover {
      background-color: gray;
    }
  }

  &.light {
    color: #080808;
    background-color: $gray;

    &:hover, &:focus {
      background-color: transparentize($gray, 0.1);
    }
  }

  &.success {
    color: white;
    background-color: $success-color;

    &:hover, &:focus {
      background-color: transparentize($success-color, 0.1);
    }
  }

  &.danger {
    color: #080808;
    background-color: $danger-color;

    &:hover, &:focus {
      background-color: transparentize($danger-color, 0.1);
    }
  }
}



button{
  &.link{
    background-color: #0000;
    border: none;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
  color: $primary-color;

  &:hover {
    color: darken($primary-color, 10);
  }


}

.is-hidden {
  display: none;
}

.is-invisible {
  visibility: hidden;
}
