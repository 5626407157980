@import "../../utils";

.info {
  position: absolute;
  top: 25%;
  color: $green;
  opacity: 1;

  transition: opacity 2s ease-in;

  &.hidden {
    opacity: 0;
  }
}
