$mobile: 768px;
$desktop: 1024px;
$fullhd: 1408px;

@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $mobile + 1) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }
}

//Colors
$primary-color: #7289da;
$success-color: rgba(67, 181, 129, 0.8);
$danger-color: rgba(207, 73, 80, 0.8);
$warning-color: #daa520;
$gray: lightgray;
$black: #111;
$white: #eee;
$green: hsla(80, 40%, 50%, 1);
$orange-dark: #da7e35;

$input-background-color: #363a49;
$button-background-color: $primary-color;
