.feed {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 1rem;
  max-height: 100px;
  overflow-y: scroll;
  box-sizing: border-box;
}


::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-button {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #4d7be8;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 15px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}
