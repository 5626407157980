@import "../../../utils";

.message {
  &__rolled {
    &__dice {
      color: $primary-color;
    }
  }

  &__lost {
    color: $danger-color;
  }

  &__over {
    color: $warning-color;
  }
}
